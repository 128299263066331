const HostHouse = ({ lang: { pages: { haveHouse } } }) =>
<div className="h-5/6 px-6 pt-6 lg:flex lg:flex-row lg:justify-center">
  <meta name="description" content={`
    Do you have a spare sleeping place available and you don't mind helping out students?
    Sign up for our couch surfing initiative!

    or

    Are you an international student from Amsterdam and need shelter this academic year?
    Join our couchsurfing initiative!
  `}/>
  <div className="lg:max-w-4xl">
    <h1 className="font-title text-xl">
      { haveHouse.title }
    </h1>
    <p className="pt-3">
      { haveHouse.explanation }
    </p>
    <p className="pt-3">
      Are you in need for emergency housing? Then click the link below to fill in a form. Keep in mind that people are voluntarily housing you and that it is temporary, so you should keep looking for something permanent. However this is a great way to not sleep on the street! And do not forget to help us change the system, because this is not a long term solution.
    </p>
    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfNlrIIaC8Sj0vdeB5oLfByMI0OxGQe9jtQew_if1DXMtxcyA/viewform">
      <p className="pt-2 text-secondary underline">
        { haveHouse.link }
      </p>
    </a>
  </div>

</div>

const Stories = ({ stories: { title, text, disclaimer, attribute }}) => {

  return <div className="pt-5 bg-tertiary">
    <h2 className="text-xl font-title">
      { title }
    </h2>

    <div>
      
      <div className="pt-2">
        <div className="float-right pl-2">
          <img width="200" src="/stories_0.jpeg" alt="Anonymized portrait"/>
        </div>
        
        <p>
          { text[0] }
        </p>
      </div>

      <div className="py-2">
        <div className="float-left pr-2">
          <img width="200" src="/stories_1.jpeg" alt="Anonymized portrait"/>
        </div>

        <p>
          { text[1] }
        </p>
      </div>


      <p>
        { text[2] }
      </p>
    </div>

    <div className="pt-2">
      <code>
        { disclaimer }
      </code>
    </div>

    <p className="pt-2 pb-6">
      { attribute }
    </p>
  </div>
}

export default HostHouse