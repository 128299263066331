
const About = ({ lang }) => {
  const { pages: { about: { whoAreWe, theProblem, toDo } } } = lang

  return (
    <div className="px-6 pt-6 pb-12 bg-tertiary lg:flex lg:flex-row lg:justify-center">
      <div className="lg:max-w-4xl">
        <h1 className="text-2xl pb-3">
          { lang.menu.about }
        </h1>
        <div>
          <h2 className="font-title text-lg">
            {whoAreWe.title}
          </h2>
          <p>
            No Room For Us is a campaign organized by a coalition of different student groups, who are concerned about the housing situation in Amsterdam. The groups involved are <a href="http://www.srvu.org">SRVU</a>, <a href="https://asva.nl/en/homepage-english/">ASVA</a>, <a href="https://twitter.com/WOinActieVU">WOinActieVU</a> and the <a href="https://www.voorwaarts.net">Communistische Jongerenbeweging</a>.
          </p>
        </div>

        <div>
          <h2 className="font-title text-lg pt-6">
            {theProblem.title}
          </h2>
          <p>
            {theProblem.text}
          </p>
        </div>

        <div>
          <h2 className="font-title text-lg pt-6">
            {toDo.title}
          </h2>
          <p>
            {toDo.text}
            <br />
            <div className="">
              <div className="pt-2 text-secondary underline">
                <a href="/host-house">{toDo.links.host}</a>
              </div>
              <div className="pt-2 text-secondary underline">
                <a href="/need-house">{toDo.links.need}</a>
              </div>
              <div className="pt-2 text-secondary underline">
                <a href="/join-us">{toDo.links.help}</a>
              </div>
            </div>
          </p>
        </div>
      </div>
    </div>
  )
}

export default About