const Home = ({ lang }) => {
  const { pages: { home }} = lang
  return (
    <div className="h-8/9 px-6 pt-6 lg:flex lg:flex-row lg:justify-center">
      <meta name="description" content={`
        No Room For Us is an initiative for better and more student housing.
        We also organize a couch surfing initiative,
        so international students in Amsterdam do not have to be homeless.
      `} />
      <div className="lg:max-w-4xl">
        <h1 className="text-left font-title text-4xl pb-6 max-w-0 uppercase">
          {lang.title}
        </h1>
        <p className="">
          {home.text}
        </p>
        <div className="grid grid-cols-1 pt-6 gap-x-6">
          <Button text={ home.help } to="/host-house-need-house" />
        </div>
        <br/>
        <p className="">
          
The growth policy of VU and UvA has turned out to be uncontrolled, unsustainable, and irresponsible, this is why we demand that VU and UvA fix this housing crisis among students.<br/>
<br/>
Last year we demanded:<br/>
<ul className="list-disc list-inside pl-2">
<li>Arrangement of emergency housing  for all homeless VU students</li>
<li>Improvement of communication to internationals about the Amsterdam housing market.</li>
<li>Building 5000 extra long-term student rooms near VU.</li>
<li>In 2022 the housing policy of VU and UvA is still subpar, therefore we continue our mission to ensure no students face homelessness.</li>
</ul>
</p>
<br/>
<p className="">
<span className="bold">Note:</span> do you have trouble to register yourself at an address? We can help! Visit <a class="underline" href="https://asva.nl/en/unable-to-register-your-address-asva-can-help/" target="_blank" rel="noopener">this</a> site to find more information.
</p>
</div>
</div>
  )
}

const Button = ({ text, to }) =>
<div className="h-full py-1 rounded-lg bg-primary text-xl text-white font-title">
  <div className="h-full flex flex-col justify-center text-center">
    <a href={ to }>{ text }</a>
  </div>
</div>



export default Home